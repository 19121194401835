import { Button, ModalFooter, Switch } from '@chakra-ui/react';
import {
  DataType,
  PropertyScope,
  RpgConfig,
  RpgConfigProperty,
  ValueType,
} from '@common/studio-types';
import {
  Field,
  Icon,
  ModalBody,
  StackDialog,
  TextInput,
} from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { SmallHint } from '../Hint';
import { EnumPropertyForm } from './EnumPropertyFormV2';
import { NumberPropertyForm } from './NumberPropertyFormV2';
import { StringPropertyForm } from './StringPropertyFormV2';

type PropertyFormProps = {
  onChange: (data: RpgConfigProperty) => void;
  data?: RpgConfigProperty;
  onRemove: () => void;
  rpgConfig: RpgConfig;
  isOpen: boolean;
  onClose: () => void;
};

const icons: Record<DataType, string> = {
  [DataType.Number]: 'number-type',
  [DataType.String]: 'text-type',
  [DataType.Enum]: 'enum-type',
};

export const PropertyForm: React.FC<PropertyFormProps> = (props) => {
  const { rpgConfig } = props;
  const [localData, setLocalData] = useState(
    props.data ? { ...props.data } : undefined,
  );

  const isInvalidMaxValue = useMemo(() => {
    if (localData?.config?.dataType !== DataType.Number) {
      return false;
    }

    return (
      (localData?.config?.maxValue?.type === ValueType.Property &&
        !localData?.config?.maxValue?.ref) ||
      (localData?.config?.maxValue?.type === ValueType.Item &&
        !localData?.config?.maxValue?.ref)
    );
  }, [localData]);
  const isInvalidMinValue = useMemo(() => {
    if (localData?.config?.dataType !== DataType.Number) {
      return false;
    }

    return (
      (localData?.config?.minValue?.type === ValueType.Property &&
        !localData?.config?.minValue?.ref) ||
      (localData?.config?.minValue?.type === ValueType.Item &&
        !localData?.config?.minValue?.ref)
    );
  }, [localData]);
  const isInvalidPlayerChoice = useMemo(() => {
    if (localData?.config?.dataType !== DataType.Enum) {
      return false;
    }

    return localData?.config?.options.some((option) => option.label === '');
  }, [localData]);

  const isInvalidValue =
    !localData?.name ||
    isInvalidMaxValue ||
    isInvalidMinValue ||
    isInvalidPlayerChoice;

  const icon = localData ? icons[localData.config.dataType] : undefined;

  const onSave = () => {
    props.onChange(localData!);
    props.onClose();
  };

  useEffect(() => {
    setLocalData(props.data ? { ...props.data } : undefined);
  }, [props.data]);

  const onScopeChange = (shouldResetOnEpisode: boolean) => {
    const scope: PropertyScope = shouldResetOnEpisode
      ? PropertyScope.Episode
      : PropertyScope.Series;

    setLocalData({ ...localData!, scope });
  };

  const onRemoveClick = () => {
    props.onRemove();
    props.onClose();
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.isOpen && localData?.name === '') {
      setTimeout(() => ref.current?.querySelector('input')?.focus(), 300);
    }
  }, [props.isOpen, localData]);

  return (
    <StackDialog
      title="Edit Property"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalBody>
        {localData && (
          <DetailsContainer ref={ref}>
            <Field label="Name">
              <TextInput
                autoComplete="off"
                leftIcon={<Icon name={icon!} size={rawDimensions.size16} />}
                placeholder="Name"
                hint="You can use text properties to store character names, quest titles, or professions."
                maxCharacters={20}
                isInvalid={!localData.name}
                value={localData.name}
                onChange={(e) =>
                  setLocalData({ ...localData, name: e.target.value })
                }
              />
            </Field>
            {localData.config.dataType === DataType.Number && (
              <NumberPropertyForm
                propertyId={localData.id}
                rpgConfig={rpgConfig}
                data={localData.config}
                onChange={(config) => setLocalData({ ...localData, config })}
              />
            )}
            {localData.config.dataType === DataType.String && (
              <StringPropertyForm
                data={localData.config}
                onChange={(config) => setLocalData({ ...localData, config })}
              />
            )}
            {localData.config.dataType === DataType.Enum && (
              <EnumPropertyForm
                data={localData.config}
                onChange={(config) => setLocalData({ ...localData, config })}
              />
            )}
            <Field
              label="Reset property every episode"
              rightButton={
                <Switch
                  isChecked={localData.scope === PropertyScope.Episode}
                  onChange={(e) => onScopeChange(e.target.checked)}
                  size="lg"
                />
              }
            >
              <SmallHint>
                Selecting this will reset the value of this property when the
                episode is complete.
              </SmallHint>
            </Field>
          </DetailsContainer>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="default" onClick={onRemoveClick}>
          Delete
        </Button>
        <Divider />
        <Button variant="default" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave} isDisabled={isInvalidValue}>
          Save
        </Button>
      </ModalFooter>
    </StackDialog>
  );
};

const Divider = styled.div`
  flex: 1;
`;

const DetailsContainer = styled.div`
  width: 100%;
  padding: ${dimensions.size16};
`;
